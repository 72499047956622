<template>
  <b-card v-if="isVisible" ref="questionForm" no-body class="p-3 mt-3">
    <validation-observer v-slot="{ valid }">
      <b-form-group
        label-class="font-weight-bold text-uppercase"
        :label="$t('page.questions.questionType')"
      >
        <b-form-select v-model="selectedQuestionType">
          <b-form-select-option
            v-for="(questionType, index) in questionTypeOptions"
            :key="index"
            :value="questionType.value"
          >
            {{ $t(`page.questions.typeText.${questionType.text}`) }}
          </b-form-select-option>
        </b-form-select>
      </b-form-group>
      <b-form-group
        class="mt-4"
        label-class="font-weight-bold text-uppercase"
        :label="$t('page.questions.addQuestionText')"
      >
        <validation-provider
          immediate
          name="Question"
          :rules="{ max: maxInputLength }"
          v-slot="validationContext"
        >
          <b-input-group>
            <b-form-input
              v-model="questionModel.text"
              :state="getValidationState(validationContext)"
            ></b-form-input>
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-input-group>
        </validation-provider>
        <p
          v-if="activeQuestionTextLength <= maxInputLength"
          class="small text-muted"
        >
          {{ questionTextLimitMsg }}
        </p>
        <p v-else class="small text-red">
          {{ activeQuestionTextLength }} / {{ maxInputLength }}
        </p>
      </b-form-group>
      <div v-if="hasAnswersSection" class="mt-4">
        <b-row class="no-gutters align-items-end mb-3">
          <h6 class="mb-0 mr-1 text-uppercase font-weight-bold">
            {{ $t("page.questions.addAnswers") }}
          </h6>
          <span class="small">{{ $t("page.questions.addAnswersSubMsg") }}</span>
        </b-row>
        <draggable
          :list="questionModel.answerList"
          group="answers"
          @start="answerDrag = true"
          @end="answerDrag = false"
        >
          <transition-group name="fade" mode="out-in">
            <div v-for="answer in questionModel.answerList" :key="answer.id">
              <b-card no-body class="mb-3 pr-3">
                <b-row class="no-gutters align-items-center">
                  <b-col class="prepend__answer-icon col-auto mr-3 p-2">
                    <!-- <b-icon
                    class="clickable"
                    icon="arrows-move"
                    variant="white"
                    font-scale="1.6"
                  ></b-icon> -->
                    <img
                      src="./../../assets/general-icons/drag.svg"
                      class="drag__icon clickable"
                    />
                  </b-col>
                  <b-col>
                    {{ answer.text }}
                  </b-col>
                  <b-col class="col-auto">
                    <img
                      src="./../../assets/general-icons/pencil.svg"
                      class="action__icon clickable mr-3"
                      @click="editAnswer(answer)"
                    />
                    <img
                      src="./../../assets/general-icons/trash.svg"
                      class="action__icon clickable"
                      @click="removeAnswer(answer.id)"
                    />
                  </b-col>
                </b-row>
              </b-card>
            </div>
          </transition-group>
        </draggable>
        <div class="new__question-section p-3 w-100">
          <b-input-group class="mt-3">
            <b-form-input v-model="answerModel.text"></b-form-input>
          </b-input-group>
          <b-row class="no-gutters mt-3 justify-content-end">
            <b-button
              v-if="isEditAnswerActive"
              variant="link"
              class="text-dark text-decoration-none text-uppercase mr-1"
              @click="cancelAnswerChanges"
              >{{ $t("button.cancel") }}</b-button
            >
            <b-button
              variant="red"
              :disabled="isAnswerFormValid"
              @click="saveAnswer"
            >
              {{ $t(`button.${answerFormActionBtnLabel}`) }}
            </b-button>
          </b-row>
        </div>
        <div class="mt-3">
          <b-form-checkbox v-model="questionModel.randomAnswerOrder">
            <span class="text-red font-weight-bold">
              {{ $t("page.questions.questionRandomOrder") }}
            </span>
          </b-form-checkbox>
        </div>
      </div>
      <b-row class="no-gutters mt-3">
        <b-button
          variant="link"
          class="ml-auto text-dark text-decoration-none text-uppercase"
          @click="cancelChanges"
          >{{ $t("button.cancel") }}</b-button
        >
        <b-button
          class="text-uppercase"
          :disabled="!isFormValid || !valid"
          @click="saveQuestion"
        >
          {{ $t("button.saveQuestion") }}
        </b-button>
      </b-row>
    </validation-observer>
  </b-card>
</template>

<script>
import Utils from "./../../Utils";
import uniqueId from "lodash/uniqueId";
export default {
  props: {
    question: {
      type: Object,
      required: true
    },
    formType: {
      type: String,
      required: false,
      default: "NEW"
    }
  },
  watch: {
    formType: {
      handler: function() {
        this.questionModel = this.question;
        if (this.formType !== "")
          this.$nextTick(() => {
            this.$refs.questionForm.scrollIntoView({ behavior: "smooth" });
          });
      },
      immediate: true
    }
  },
  data() {
    return {
      answerDrag: false,
      questionModel: {},
      answerModel: {
        id: null,
        text: ""
      },
      maxInputLength: 240,
      minTitleLength: 4,
      isEditAnswerActive: false
    };
  },
  computed: {
    isVisible() {
      return (
        this.questionModel.type === Utils.QUESTION_TYPE.SINGLE_CHOICE ||
        this.questionModel.type === Utils.QUESTION_TYPE.MULTI_CHOICE ||
        this.questionModel.type === Utils.QUESTION_TYPE.OPEN_ENDED ||
        this.questionModel.type === Utils.QUESTION_TYPE.SCALE
      );
    },
    questionTextLimitMsg() {
      return this.$t("charactersRemaining", {
        length: this.maxInputLength - this.activeQuestionTextLength
      });
    },
    hasAnswersSection() {
      return (
        this.questionModel.type === Utils.QUESTION_TYPE.SINGLE_CHOICE ||
        this.questionModel.type === Utils.QUESTION_TYPE.MULTI_CHOICE
      );
    },
    isAnswerFormValid() {
      return !(this.answerModel.text.length > 0);
    },
    questionTypeOptions() {
      return this.$store.getters["question/getQuestionTypeOptions"];
    },
    selectedQuestionType: {
      get() {
        return this.questionModel.type;
      },
      set(questionType) {
        this.questionModel.type = questionType;
      }
    },
    isFormValid() {
      let isAnswerValid = true;
      if (this.hasAnswersSection)
        isAnswerValid = this.questionModel.answerList.length > 1;
      return (
        isAnswerValid &&
        this.activeQuestionTextLength > this.minTitleLength &&
        this.activeQuestionTextLength <= this.maxInputLength
      );
    },
    answerFormActionBtnLabel() {
      if (this.isEditAnswerActive) return "save";
      else return "add";
    },
    activeQuestionTextLength() {
      return this.questionModel.text.length;
    }
  },
  methods: {
    getValidationState({ valid = null }) {
      return valid ? null : valid;
    },
    resetAnswerModel() {
      this.answerModel = {
        id: null,
        text: ""
      };
    },
    editAnswer(answer) {
      this.answerModel = { ...answer };
      this.isEditAnswerActive = true;
    },
    cancelAnswerChanges() {
      this.resetAnswerModel();
      this.isEditAnswerActive = false;
    },
    saveAnswer() {
      if (this.isEditAnswerActive && this.answerModel.id) {
        const index = this.questionModel.answerList.findIndex(
          a => a.id === this.answerModel.id
        );
        if (index > -1)
          this.questionModel.answerList[index] = { ...this.answerModel };
      } else {
        this.addAnswer();
      }
      this.resetAnswerModel();
      this.isEditAnswerActive = false;
    },
    addAnswer() {
      this.answerModel.id = uniqueId("answer_");
      this.questionModel.answerList.push({ ...this.answerModel });
    },
    removeAnswer(answerId) {
      this.questionModel.answerList = this.questionModel.answerList.filter(
        ans => {
          return ans.id !== answerId;
        }
      );
    },
    cancelChanges() {
      this.$emit("cancelChanges");
    },
    saveQuestion() {
      if (this.formType === "NEW")
        this.$emit("addQuestion", { ...this.questionModel });
      else if (this.formType === "EDIT")
        this.$emit("updateQuestion", { ...this.questionModel });
    }
  }
};
</script>

<style scoped>
.prepend__input {
  width: 50px;
  color: white;
  background-color: var(--gray);
}
.new__question-section {
  background-color: var(--gray-light);
  border: 2px dashed var(--gray);
}
.prepend__answer-icon {
  background-color: var(--gray);
  width: 50px;
  text-align: center;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.4s ease;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.action__icon {
  width: 20px;
  filter: invert(0%) sepia(2%) saturate(6551%) hue-rotate(352deg)
    brightness(99%) contrast(100%);
}
.drag__icon {
  width: 26px;
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(43deg)
    brightness(102%) contrast(101%);
}
</style>
