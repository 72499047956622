<template>
  <b-row class="no-gutters">
    <b-col class="col-12 col-md-8 col-lg-6">
      <h4 class="font-weight-normal text-muted title__2">
        {{ $t("page.questions.description") }}
      </h4>
      <b-card no-body class="rounded-0 p-3 mt-4">
        <h4 class="mb-4">
          {{ $t("page.questions.limitUploadMessage") }}
        </h4>
        <b-button
          variant="red"
          block
          :disabled="isFormSectionActive || !hasMoreQuestions"
          @click="openQuestionTypeModal"
        >
          <b-row
            class="no-gutters justify-content-between align-items-center py-1"
          >
            <b-col class="text-left h5 font-weight-normal mb-0">
              {{ $t("page.questions.addQuestion") }}
            </b-col>
            <b-col class="col-auto">
              <img
                src="./../assets/general-icons/plus-circle.svg"
                class="plus__icon"
              />
            </b-col>
          </b-row>
        </b-button>
      </b-card>

      <!-- QUESTION LIST -->
      <draggable
        v-model="questionList"
        group="questions"
        @start="questionDrag = true"
        @end="questionDrag = false"
      >
        <transition-group name="fade" mode="out-in">
          <div
            v-for="questionItem in questionList"
            :key="questionItem.id"
            class="my-3"
          >
            <question-list-item
              :question="questionItem"
              @showQuestionDetails="showQuestionDetails"
              @removeQuestion="removeQuestion"
            />
          </div>
        </transition-group>
      </draggable>

      <question-form
        :question="activeQuestion"
        :formType="formType"
        @addQuestion="addQuestion"
        @updateQuestion="updateQuestion"
        @cancelChanges="cancelQuestionChanges"
      />
    </b-col>

    <question-type-modal
      :modalStatus="modalStatus"
      @setModalStatus="setModalStatus"
      @initNewQuestion="initNewQuestion"
    />
  </b-row>
</template>

<script>
import QuestionTypeModal from "./../components/Questions/QuestionTypeModal";
import QuestionListItem from "./../components/Questions/QuestionListItem";
import QuestionForm from "./../components/Questions/QuestionForm";
import uniqueId from "lodash/uniqueId";
import cloneDeep from "lodash/cloneDeep";
export default {
  components: {
    QuestionTypeModal,
    QuestionListItem,
    QuestionForm
  },
  data() {
    return {
      questionDrag: false,
      modalStatus: false,
      maxQuestions: 5,
      activeQuestion: {},
      questionModel: {
        id: null,
        type: "",
        text: "",
        answerList: [],
        randomAnswerOrder: false
      },
      formType: ""
    };
  },
  computed: {
    isFormSectionActive() {
      return this.activeQuestion.type !== undefined;
    },
    hasMoreQuestions() {
      return this.questionList.length < this.maxQuestions;
    },
    questionList: {
      get() {
        return this.$store.getters["question/getQuestionList"];
      },
      set(questionList) {
        this.$store.commit("question/SET_QUESTION_LIST", questionList);
      }
    }
  },
  methods: {
    resetForm() {
      this.activeQuestion = {};
      this.formType = "";
    },
    openQuestionTypeModal() {
      this.modalStatus = true;
    },
    setModalStatus(modalStatus) {
      this.modalStatus = modalStatus;
    },
    initNewQuestion(questionType) {
      // console.log(questionType);
      this.activeQuestion = cloneDeep(this.questionModel);
      this.activeQuestion.type = questionType;
      this.formType = "NEW";
    },
    cancelQuestionChanges() {
      this.resetForm();
    },
    showQuestionDetails(questionId) {
      this.formType = "EDIT";
      const question = this.questionList.find(question => {
        return question.id === questionId;
      });
      this.activeQuestion = cloneDeep(question);
    },
    addQuestion(question) {
      question.id = uniqueId("question_");
      this.$store.commit("question/ADD_QUESTION", question);
      this.resetForm();
    },
    updateQuestion(question) {
      this.$store.commit("question/UPDATE_QUESTION", question);
      this.resetForm();
    },
    removeQuestion(questionId) {
      this.$store.commit("question/REMOVE_QUESTION", questionId);
    }
  }
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.4s ease;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.plus__icon {
  width: 24px;
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(43deg)
    brightness(102%) contrast(101%);
}
</style>
