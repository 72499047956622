<template>
  <b-card
    no-body
    bg-variant="gray-light"
    class="rounded-0 border-0 h-100 min__height"
  >
    <b-row class="no-gutters justify-content-end">
      <div
        class="p-1 text-white font-weight-bold text__xs label text-uppercase"
      >
        {{ $t("example") }}
      </div>
    </b-row>
    <div class="px-2 pt-2 pb-3">
      <h6 class="mb-3 text-muted">
        {{ $t(`page.questions.exampleQuestions.${activeExampleTitle}`) }}
      </h6>
      <b-form-radio-group
        v-if="questionType === questionTypeOptions.SINGLE_CHOICE"
        :options="sportOptions"
        disabled
        stacked
      >
      </b-form-radio-group>
      <b-form-checkbox-group
        v-else-if="questionType === questionTypeOptions.MULTI_CHOICE"
        :options="sportOptions"
        disabled
        stacked
      >
      </b-form-checkbox-group>
      <b-form-textarea
        v-else-if="questionType === questionTypeOptions.OPEN_ENDED"
        class="textarea"
        disabled
        size="lg"
      ></b-form-textarea>
      <b-form-radio-group
        v-else-if="questionType === questionTypeOptions.SCALE"
        disabled
      >
        <b-row class="no-gutters">
          <b-col v-for="(number, index) in 5" :key="index">
            <div class="ml-1">{{ number }}</div>
            <b-form-radio> </b-form-radio>
          </b-col>
        </b-row>
      </b-form-radio-group>
    </div>
  </b-card>
</template>

<script>
import Utils from "./../../Utils";
export default {
  props: {
    questionType: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      sportOptions: ["Volleyball", "Football", "Basket", "Tennis", "Formula 1"],
      questionTypeOptions: Utils.QUESTION_TYPE
    };
  },
  computed: {
    activeExampleTitle() {
      switch (this.questionType) {
        case this.questionTypeOptions.SINGLE_CHOICE:
          return "singleChoice";
        case this.questionTypeOptions.MULTI_CHOICE:
          return "multiChoice";
        case this.questionTypeOptions.OPEN_ENDED:
          return "openEnded";
        case this.questionTypeOptions.SCALE:
          return "scale";
        default:
          return "";
      }
    }
  }
};
</script>

<style scoped>
.min__height {
  min-height: 300px;
}
.label {
  background-color: var(--gray);
}
.textarea {
  background-color: var(--light);
}
</style>
