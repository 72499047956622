<template>
  <b-card no-body class="px-3 py-1">
    <b-row class="no-gutters align-content-center mb-1">
      <b-col>{{ questionTypeText }}</b-col>
      <b-col class="col-auto">
        <img
          src="./../../assets/general-icons/eye.svg"
          class="action__icon clickable"
          @click="showDetails"
        />
      </b-col>
    </b-row>
    <h4>
      {{ question.text }}
    </h4>
    <hr class="mx-n3 divider" />
    <b-row class="no-gutters align-content-center justify-content-between mb-2">
      <b-col class="col-auto">
        <img
          src="./../../assets/general-icons/drag.svg"
          class="action__icon clickable"
        />
      </b-col>
      <b-col class="col-auto">
        <img
          src="./../../assets/general-icons/trash.svg"
          class="action__icon clickable"
          @click="removeQuestion"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
export default {
  props: {
    question: {
      type: Object,
      required: true
    }
  },
  computed: {
    questionTypeOptions() {
      return this.$store.getters["question/getQuestionTypeOptions"];
    },
    questionTypeText() {
      const activeType = this.questionTypeOptions.find(
        i => i.value === this.question.type
      );
      return activeType
        ? this.$t(`page.questions.typeText.${activeType.text}`)
        : "Not available";
    }
  },
  methods: {
    removeQuestion() {
      this.$emit("removeQuestion", this.question.id);
    },
    showDetails() {
      this.$emit("showQuestionDetails", this.question.id);
    }
  }
};
</script>

<style scoped>
.divider {
  border-top: 2px black dashed;
}
.action__icon {
  width: 20px;
  filter: invert(0%) sepia(2%) saturate(6551%) hue-rotate(352deg)
    brightness(99%) contrast(100%);
}
</style>
