<template>
  <b-modal
    v-model="isModalVisible"
    size="lg"
    content-class="rounded-0"
    footer-class="border-top-0"
    centered
    @ok="setSelectedOption"
  >
    <template v-slot:modal-header>
      <h5 class="mb-0 font-weight-normal">
        {{ $t("page.questions.selectQuestionType") }}
      </h5>
    </template>
    <template v-slot:default>
      <b-row class="no-gutters">
        <b-col class="col-12 col-md-5">
          <b-form-radio-group v-model="questionType" stacked>
            <b-form-radio
              v-for="(questionTypeItem, index) in questionTypeOptions"
              :key="index"
              :value="questionTypeItem.value"
              class="mt-3"
            >
              {{ $t(`page.questions.typeText.${questionTypeItem.text}`) }}
            </b-form-radio>
          </b-form-radio-group>
        </b-col>
        <b-col class="col-12 col-md-7 pl-md-3 mt-3 left__border">
          <question-example-section :questionType="questionType" />
        </b-col>
      </b-row>
    </template>
    <template v-slot:modal-footer="{ ok, cancel }">
      <b-button
        variant="gray-light"
        class="text-dark text-decoration-none"
        @click="cancel"
        >{{ $t("button.cancel") }}</b-button
      >
      <b-button variant="primary" :disabled="isOptionSelected" @click="ok">
        {{ $t("button.add") }}
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import Utils from "./../../Utils";
import QuestionExampleSection from "./QuestionExampleSection";
export default {
  props: {
    modalStatus: {
      type: Boolean,
      required: true
    }
  },
  components: {
    QuestionExampleSection
  },
  watch: {
    modalStatus: {
      handler: function() {
        if (this.modalStatus)
          this.questionType = Utils.QUESTION_TYPE.SINGLE_CHOICE;
      }
    }
  },
  data() {
    return {
      questionType: -1
    };
  },
  computed: {
    questionTypeOptions() {
      return this.$store.getters["question/getQuestionTypeOptions"];
    },
    isModalVisible: {
      get() {
        return this.modalStatus;
      },
      set(modalStatus) {
        this.$emit("setModalStatus", modalStatus);
      }
    },
    isOptionSelected() {
      return false;
    }
  },
  methods: {
    setSelectedOption() {
      this.$emit("initNewQuestion", this.questionType);
    }
  }
};
</script>

<style scoped>
.left__border {
  border-left: 1px solid black;
}
</style>
